var projects = document.getElementsByClassName("project")
var active = document.getElementsByClassName("active")

//// Get Closest Element
var getClosest = function(elem, selector) {

   // Element.matches() polyfill
   if (!Element.prototype.matches) {
      Element.prototype.matches =
         Element.prototype.matchesSelector ||
         Element.prototype.mozMatchesSelector ||
         Element.prototype.msMatchesSelector ||
         Element.prototype.oMatchesSelector ||
         Element.prototype.webkitMatchesSelector ||
         function(s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
               i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
         };
   }

   // Get the closest matching element
   for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
   }
   return null;

};
var parent;
var progressBarFill;
var videos = document.getElementsByTagName('video')
/// Change Button
function changeButtonType(btn, value) {
   btn.title = value;
   btn.innerHTML = value;
   btn.className = value;
}
var playBtn = document.getElementById("play")
var pauseBtn = document.getElementById("pause")

for (var i = 0; i < videos.length; i++) {
 
     videos[i].touchstart = function(){
        playVideo(e)
    };
}

function toggleVideo(e) {
   playVideo(e)
}

function togglePlayPause(e) {
   playVideo(e)
}

function playVideo(e) {
   var parent = getClosest(e.target, '.project');
   var loader = parent.getElementsByClassName("loader")[0]

   for (var i = 0; i < projects.length; i++) {
      projects[i].classList.remove("active")

   }
   var video = parent.getElementsByTagName("video")[0]

   video.onwaiting = function() {
      loader.style.display = "inline-block"
   };
   video.oncanplay = function() {
      loader.style.display = "none"
   };

   video.onloadstart = function() {
   };

   var allOtherVideos = [].slice.call(document.getElementsByTagName('video'), 0);
   var index = allOtherVideos.indexOf(video)
   allOtherVideos.splice(index, 1)
   for (var i = 0; i < allOtherVideos.length; i++) {
      allOtherVideos[i].pause();
   }


   var btn = parent.getElementsByTagName("button")[0]

   if (video.paused || video.ended) {
      changeButtonType(btn, 'Pause');
      parent.classList.add("active")
      findProgressBar()




      var playPromise = video.play();

      if (playPromise !== undefined) {
         playPromise.then(_ => {
               // Automatic playback started!
               // Show playing UI.
               // We can now safely pause video...
               // video.pause();
            })
            .catch(error => {
               // Auto-play was prevented
               // Show paused UI.
            });
      }

   } else {
      changeButtonType(btn, 'Play');
      video.pause();
      parent.classList.remove("active")
   }

   video.addEventListener('timeupdate', updateProgress, false);
   video.addEventListener("ended", ended, false);

}

// Update progress bar as the video plays
const updateProgress = (el) => {
   var video = el.target
   // Calculate current progress
   let value = (100 / video.duration) * video.currentTime;
   // Update the slider value
   var parent = getClosest(video, '.project');


   const progressBarFill = parent.querySelector('.progress-bar-fill');

   progressBarFill.style.width = value + '%';
}



var progressBar;

function findProgressBar() {
   var active = document.getElementsByClassName("active")[0]
   var progressBar = active.getElementsByClassName('progress-bar')[0]


   progressBar.addEventListener('click', progressBarFunction, false);
}
const progressBarFunction = (e) => {
   var active = document.getElementsByClassName("active")[0]
   var video = active.getElementsByTagName("video")[0]
   var progressBar = e.target
   let pos = (e.pageX - (progressBar.offsetLeft + progressBar.offsetParent.offsetLeft)) / progressBar.offsetWidth;
   video.currentTime = pos * video.duration;
}

const ended = () => {
   var active = document.getElementsByClassName("active")[0]
   var video = active.getElementsByTagName("video")[0]
   video.load();
}

function toggleMute(e) {
   var parent = getClosest(e.target, '.project');
   var video = parent.getElementsByTagName("video")[0]
   var btn = parent.getElementsByTagName("button")[1]
   var videos = document.getElementsByTagName("video")
   var muteBtn = document.getElementsByClassName("mute")
   for (var i = 0; i < projects.length; i++) {
      var video = projects[i].getElementsByTagName("video")[0]
      var btn = projects[i].getElementsByTagName("button")[1]
      if (video.muted) {
         changeButtonType(btn, 'Mute');
         video.muted = false;
      } else {
         changeButtonType(btn, 'Unmute');
         video.muted = true;
      }

   }
}